<template>
<div  id="app" v-if='is_loading'>

   <div class="preloader"></div>
 

     
  </div>

  <div id="app" v-else>
  
  
  <Header v-if="Cate!=null && MyNews!=null" :MyNews="MyNews"  :Categories="Cate" :General="General"></Header>
  <!-- header End -->

   <div>
   <!-- Home Slider Start -->
    <Slideshow v-if="banner_home!=null" :banner_home="banner_home"></Slideshow>


  <!-- Home Slider End -->

  <!-- About div Start -->
  <div>
    <div class="container">
      <div class="row">

          <Listcate v-if="Cate!=null && MyNews!=null" :MyNews="MyNews" :Categories="Cate" ></Listcate>

          <div class="col-md-3 col-sm-3 mt-10 boxdanhmuc" v-else>
              <nav id="sidebar">
                <p>{{$t('general.cate')}}</p>
                <ul class="list-unstyled components">
                  
                  <li class="active" v-for="(v1,k1) in 5" :key="k1">
                    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                        <a href="#gioi-thieu" >
                            <Skeleton> </Skeleton>
                        </a>
                        <ul class="list-unstyled collapse show">
                          <li  v-for="(v,k) in 6" :key="k">
                            <a href="#" title="#">
                                 <Skeleton ></Skeleton>
                            </a>
                          </li>
                          
                     
                         </ul>
                     </SkeletonTheme>
                  </li>

                  
                </ul>
              </nav>
            </div>

           <router-view/>

          
          <Banner  :banner_list="banner_list" ></Banner>
      </div>
    </div>
  </div>
</div>

  <!-- Footer Area Start -->
  <Footer v-if="Cate!=null && MyNews!=null" :MyNews="MyNews" :Categories="Cate" :General="General"></Footer>
  <!-- Footer Area End -->
  <!-- BACK TO TOP SECTION -->
  <div class="back-to-top bg-primary-color">
    <i class="fab fa-angle-up"></i>
  </div>
  <div id="box_callbot" class="col-lg-3 col-md-5 col-sm-12 box_callbot">
    <div class="box_callbot_content">
      <a href="https://vexemiendong.com.vn/Trang_Dat_Ve.aspx" title="ĐẶT VÉ XE" target="_blank">
        <i class="fas fa-arrow-alt-circle-right animated3 flash page_speed_1445864187"></i>
        ĐẶT VÉ XE
      </a>
    </div>
  </div>

     
  </div>
</template>

<script>


  import Header from "@/components/default/Header.vue";
  import Footer from "@/components/default/Footer.vue";
  
  import Listcate from "@/components/default/Listcate.vue";
  import Banner from "@/components/default/Banner.vue";
  import Slideshow from "@/components/default/Slideshow.vue";
  import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import "../assets/default/css/a.css";
  import "../assets/default/css/trung.css";

  import axios from 'axios'; 

//import { mapGetters } from 'vuex'

export default {
  data(){
    return {
       banner_home:null,
       banner_list:null,
       is_loading:true,
       General:null,
       Cate:null,
       MyNews:null,
       myscript:[],

       meta:{
          title:'',
          description:"",
          viewport:"width=device-width, initial-scale=1",

       }
    }
  },
  components: {
      Footer,
      Header,
      Listcate,
      Banner,
      Slideshow,
      Skeleton,
      SkeletonTheme
  },
  mounted(){
    
  },

  created() {
          
      var id=  this.$i18n.locale=='vn' ?  "7634739a-9df7-4e84-a52d-d5160e7b5f17" : "f3bba411-c4bc-44b6-a413-593d52e123e1";
     
     axios.get(window.my_api+"api/general/get-general-by-id?generalid="+id,{
        headers:{
          language: this.$i18n.locale
        }
      })
           .then((res)=>{
               if(res.status==200){
                  this.is_loading=false;

                  if(this.$i18n.locale=='vn'){
                     this.General= res.data.data;
                  }else{
                    let data=[];
                    data=res.data.data;
                    data['name']=res.data.data['name_EN'];
                    data['description']=res.data.data['description_EN'];
                    data['slogan']=res.data.data['slogan_EN'];
                    data['address']=res.data.data['address_EN'];
                    data['sitename']=res.data.data['sitename_EN'];
                  
                     this.General= data;

                    

                  }

                   this.meta.title=this.General['name'];
                    this.meta.description=this.General['description'];

                    if(res.data.data['flagIndex']=='2'){
                        this.meta.viewport="width=1300";
                    }
                  

                 //language=='vn' ? res.data.data[0] : res.data.data[1];
                   
                   
               }else{
                  console.log(res);
                 alert("Hệ thông đang bảo trì. vui lòng quay lại sau");
               }
           },(res)=>{
                console.log(res);
               alert("Hệ thông đang bảo trì. vui lòng quay lại sau.");
              this.meta.viewport= 'width=1300';
              this.meta.title= 'Đang bảo trì...';
           })
         


            axios.get(window.my_api+"api/newscate/get-newscates",{
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
                  .then( (res)=>{
                      
                      if(res.status==200){
                          

                          this.Cate=res.data.data;

                          
                          
                      }else {
                        console.log("ERROR",res);
                      }
                       

                    });

        axios.get(window.my_api+"api/news/get-news-homepage",{
                        headers:{
                          language: this.$i18n.locale
                        }
                      })
                      .then((res)=>{
                          this.MyNews=res.data.data.records;
                          
                      });  

                        
          axios.get(window.my_api+"api/banner/get-banners?language=",{
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
                .then( (res)=>{
                   
                  if(res.status==200){
            this.banner_home=res.data.data.filter(n=> n.flagIndex=='1').sort( (a,b)=>a.flagContent*1-b.flagContent*1 );
                          
                      this.banner_list=res.data.data.filter(n=> n.flagIndex=='2').sort( (a,b)=>a.flagContent*1-b.flagContent*1 );
                  }
             

          });              
  },
  methods:{
      loadLayout(){
        
        
            this.myscript=[
              
                {
                  src:"/layout/app.js",
                  type:"text/javascript"
                },
               
                
              ];

            //     window.jQuery=window.$=require("jquery");
            // this.myscript=[
            //     {
            //       src:"/layout/js/jquery.v1.12.4.min.js",
                 
            //     },
            //     {
            //       src:"/layout/js/popper.min.js",
                  
            //     },
            //     {
            //       src:"/layout/js/vanilla-lazyload.min.js",
                  
            //     },
            //     {
            //       src:"/layout/js/owl.js",
                
            //     },
            //     {
            //       src:"/layout/js/bootstrap.min.js",
                  
            //     },
            //     {
            //       src:"/layout/js/jquery-core-plugins.js",
                 
            //     },
            //     {
            //       src:"/layout/js/select2.min.js",
                 
            //     },
                
            //     {
            //       src:"/layout/js/main.js",
                 
            //     }
                
            //   ];
           
        //setTimeout(function(){
        //     let recaptchaScript = document.createElement('script')
        //     recaptchaScript.setAttribute('src', "/layout/js/jquery.v1.12.4.min.js")
        //     recaptchaScript.setAttribute("type","text/javascript")
        //     document.head.appendChild(recaptchaScript);


        //     let recaptchaScript1 = document.createElement('script')
        //     recaptchaScript1.setAttribute('src', "/layout/js/popper.min.js")
        //     recaptchaScript1.setAttribute("type","text/javascript")
        //     document.head.appendChild(recaptchaScript1);

        //     let recaptchaScript2 = document.createElement('script')
        //     recaptchaScript2.setAttribute('src', "/layout/js/vanilla-lazyload.min.js")
        //     recaptchaScript2.setAttribute("type","text/javascript")
        //     document.head.appendChild(recaptchaScript2);


        //     let recaptchaScript3 = document.createElement('script')
        //     recaptchaScript3.setAttribute('src', "/layout/js/bootstrap.min.js")
        //     recaptchaScript3.setAttribute("type","text/javascript")
        //     document.head.appendChild(recaptchaScript3);


        //     let recaptchaScript4 = document.createElement('script')
        //     recaptchaScript4.setAttribute('src', "/layout/js/jquery-core-plugins.js")
        //     recaptchaScript4.setAttribute("type","text/javascript")
        //     document.head.appendChild(recaptchaScript4);


        //     let recaptchaScript5 = document.createElement('script')
        //     recaptchaScript5.setAttribute('src', "/layout/js/select2.min.js")
        //     recaptchaScript5.setAttribute("type","text/javascript")
        //     document.head.appendChild(recaptchaScript5);
 
        // 

        //     let recaptchaScript6 = document.createElement('script')
        //     recaptchaScript6.setAttribute("type","text/javascript")
        //     recaptchaScript6.setAttribute('src', "/layout/js/main.js")
           
        //     document.head.appendChild(recaptchaScript6);

            
      }
  },
  metaInfo(){
    return {
     
        title: this.meta.title,
       
       // titleTemplate: '%s | Bến xe miền đông',
         meta: [
          { charset: 'utf-8' },
          // { name: 'viewport', content: 'width=1300' },
          { name: 'viewport', content: this.meta.viewport },
          { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
          { name: 'description', content: this.meta.description },
        ],

        htmlAttrs:{
           lang:'vn'
        },

        
        script: this.myscript,
        ssrAttribute:"data-vue-meta-server-rendered"
      }
  }
 


}
</script>

