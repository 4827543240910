

<template>
	<div class="banner-div">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-8 banner_left_cus">


          <div class="home-carousel owl-theme owl-carousel" v-if="is_loading_slideshow">
            <div class="slide-item1">
              <div class="image-layer1">
                 <a href="#" title="#">
                     <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                        <Skeleton width="1000"  height="100"></Skeleton>
                      </SkeletonTheme>
                 </a>
              </div>

            </div>
            
          </div>

          <div class="home-carousel owl-theme owl-carousel" v-else>
           

            <div class="slide-item1" v-for="(s,k) in slideshow" :key="k">
              <div class="image-layer1">
                <a :href="s.links" :title="s.name">
                  <img   :src="myurl+''+s.picture" :alt="s.name" />
                </a>
              </div>

            </div>
            
          </div>


          
        </div>

        <div class="col-md-4 col-sm-4 banner_right_cus" v-if="banner_home==null">
          <a href="#" title="#"> 
                     <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                         <Skeleton height="300"></Skeleton>
                      </SkeletonTheme>
          </a>
          <a href="#" title="#">
               <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                        <Skeleton height="300"></Skeleton>
                      </SkeletonTheme>
          </a>
        </div>

         <div class="col-md-4 col-sm-4 banner_right_cus" v-else >
         
          <a  v-for="(b,k) in banner_home" :key="k"  :href="b.description" :title="b.name" target="_blank">
              <img :src="myurl+''+b.picture" :alt='b.name'/>
          </a>

        </div>

      </div>
    </div>
  </div>
</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  import axios from 'axios';
export default {
  name: 'Home',
 
  data() {
    return {
      is_loading_slideshow:true,
      myurl:window.my_url,
      slideshow:null,

     
      

    };
  },

  components: {
    Skeleton,
    SkeletonTheme
    
  },
  props:['banner_home'],
  created(){
       let t1=  axios.get(window.my_api+"api/slideshow/get-slideshows", {
                                 headers: {
                                   language: 'vn'
                                 }
                  })
          .then( (res)=>{
             this.is_loading_slideshow=false;
            if(res.status==200){
                this.slideshow=res.data.data;
                
            }
             

          });
      
     Promise.all([t1]).then(()=>{
        this.$parent.loadLayout(); 
      }); 
      
  }

}
</script>