<template>

  <div class="col-md-3 col-sm-3 mt-10 boxdanhmuc" v-if="is_menu">
          <nav id="sidebar">
            <p>{{$t('general.cate')}}</p>
            <ul class="list-unstyled components" v-if="is_loading || Categories==null || MyNews==null">
              
              <li class="active" v-for="(v1,k1) in 5" :key="k1">
                <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                    <a href="#gioi-thieu" >
                        <Skeleton> </Skeleton>
                    </a>
                    <ul class="list-unstyled collapse show">
                      <li  v-for="(v,k) in 6" :key="k">
                        <a href="#" title="#">
                             <Skeleton ></Skeleton>
                        </a>
                      </li>
                      
                 
                     </ul>
                 </SkeletonTheme>
              </li>

              
            </ul>


             <ul class="list-unstyled components" v-else>
              
            
                  
                  <li v-for="(cate,key) in Parent" :key="key" >
                    <a :href="'#tin-tuc'+key" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
                        {{cate.name}}
                    </a>
                   
                     <ul class="list-unstyled collapse show"  :id="'tin-tuc'+key"  >

                      <li v-for="(n,kn) in MyNews.filter(n => n.cateId== cate.id ).sort((a, b) => a.orderBy - b.orderBy ) " :key="kn">

                        <a :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:n.slug}}).href" :title="n.name">- {{n.name}}</a>

                      </li>

                    </ul>
                  </li>
             
            </ul>



          </nav>
          <div class="mrb-20">
            <a href="#" title="#">
              <img class="lazy" src="../../assets/default/images/b3.png" />
            </a>
          </div>
    </div>


   <div class="col-md-3 col-sm-3 mt-10 boxdanhmuc" v-else >
          <nav id="sidebar">
            <p>{{$t('general.cate')}}</p>
            <ul class="list-unstyled components" v-if="is_loading">
              
              <li class="active" v-for="(v1,k1) in 5" :key="k1">
                <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                    <a href="#gioi-thieu" >
                        <Skeleton> </Skeleton>
                    </a>
                    <ul class="list-unstyled collapse show">
                      <li  v-for="(v,k) in 6" :key="k">
                        <a href="#" title="#">
                             <Skeleton ></Skeleton>
                        </a>
                      </li>
                      
                 
                     </ul>
                 </SkeletonTheme>
              </li>
              
            </ul>


             <ul class="list-unstyled components" v-else>
              
            
                  
                  <li v-for="(cate,key) in Parent" :key="key" >
                    <a :href="'#tin-tuc'+key" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
                        {{cate.name}}
                    </a>
                    <ul class="list-unstyled collapse show" :id="'tin-tuc'+key"  v-if="cate.slug!='gioi-thieu'">

                      <li v-for="(child,k) in Cate.filter( e =>  e.parentId==cate.id).sort((a, b) => a.orderBy - b.orderBy ) " :key="k">


                        <a  :href="$router.resolve({name: 'news.cate',params:{locale:$i18n.locale,slug:child.slug}}).href" :title="child.name">- {{child.name}}</a>
                      </li>
                      

                    </ul>
                     <ul class="list-unstyled collapse show" v-else  :id="'tin-tuc'+key"  >

                      <li v-for="(n,kn) in News" :key="kn">

                        <a :href="$router.resolve({name: 'news.detail',params:{locale:$i18n.locale,slug:n.slug}}).href" :title="n.name">- {{n.name}}</a>

                      </li>

                    </ul>
                  </li>
             
            </ul>



          </nav>
          <div class="mrb-20">
            <a href="#" title="#">
              <img class="lazy" src="../../assets/default/images/b3.png" />
            </a>
          </div>
    </div>




</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  
export default {
 
 
  data() {
    return {  
      
      myurl:window.my_url,
      is_loading:true,
      Parent:null,
      Child:[],
      News:null,
      Cate:null,
      is_detail:[],
      is_menu:false
    };
  },

  components: {

    Skeleton,
    SkeletonTheme
    
  },
  props:['Categories',"MyNews"],
  mounted(){

      

      var is_menu=false;

      if(this.Categories!=null && this.MyNews!=null ){
              this.Cate=this.Categories;


              if(this.$router.currentRoute.name=="news.cate" || this.$router.currentRoute.name=='news.detail'){
                   var Parents = this.Categories.filter(n => n.parentId==null && n.isMenu==1 ).sort((a, b) => a.orderBy - b.orderBy );
                   var slug=[];

                 for (var y = Parents.length - 1; y >= 0; y--) {
                    slug.push(Parents[y].slug);

                     var my_cate=this.Categories.filter(n => n.parentId==Parents[y].id);

                     for (var x = my_cate.length - 1; x >= 0; x--) {
                         slug.push( my_cate[x].slug);

                         var my_news= this.MyNews.filter(n => n.cateId== my_cate[x].id );

                         for (var z = my_news.length - 1; z >= 0; z--) {
                             slug.push( my_news[z].slug);
                             

                            

                         }
                     }


                     
                 }

                  
                  if( slug.indexOf( this.$router.currentRoute.params.slug ) > -1 ){
                      is_menu=true;
                  }

              }
              
             // console.log(slug);
              
              this.is_menu=is_menu;

           
              if(is_menu){
                  this.Parent=my_cate;
                     this.is_loading=false;
              }else{ 

                this.Parent = this.Cate.filter(n => n.parentId==null && n.isMenu==2 )
                                       .sort((a, b) => a.orderBy - b.orderBy );
                

                  for (var i = this.Parent.length - 1; i >= 0; i--) {
                        let p = this.Parent[i];

                       

                        if(p.slug=="gioi-thieu"){
                               this.News= this.MyNews.filter(n => n.parentId== p.id ).sort((a, b) => a.orderBy - b.orderBy );
                                 this.is_loading=false;

                              
                          
                          
                      
                        }
                  }
              }

      



         }
      
  },  
  methods:{
  
  }
}


</script>